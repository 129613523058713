<style lang="scss" scoped>
	span {
		display: block;
		margin-top: 10px;
		font-size: 14px;
	}

	h2 {
		margin: 50px 0 40px;
		font-weight: bold;
		font-size: 20px;
	}

	p + strong {
		margin-top: 30px;
	}

	strong {
		margin-bottom: 5px;
	}

	p:not(.non-italic) {
		font-style: italic;
	}

	.bg-img {
		position: absolute;
		z-index: -3;
		width: calc(100% - 15vw);
		left: 0;
		top: 150px;
		max-width: 527px;
		margin: 0 auto;
		margin-left: 15vw;
	}
</style>

<template>
	<section>
		<img src="@/../public/img/piano-bg.jpg" alt="piano" class="bg-img" />
		<h1>Klavierstimmen</h1>

		<p class="non-italic">
			Die Klavierstimmung wird pauschal berechnet, inklusive Anfahrt und Mehrwertsteuer. Für mehrere Instrumente gibt es Rabatte. Preise auf Anfrage.<br />
			Klavierstimmen gilt als „haushaltsnahe Dienstleistung“ und ist steuerlich absetzbar für jedermann.
		</p>

		<h2>
			Häufig gestelle Fragen
		</h2>

		<strong>Wie oft muss ein Klavier gestimmt werden?</strong>
		<p>
			Antwort: Einmal im Jahr ist ein guter Rhythmus für ein gespieltes Klavier. Anspruchsvolle Spieler lassen mehrmals pro Jahr stimmen.
		</p>
		<strong>Gibt es einen günstigen Zeitpunkt für eine Klavierstimmung?</strong>
		<p>
			Antwort: Ja, allerdings nur wenn Sie mehrmals jährlich stimmen lassen. Günstig sind dann die Monate Oktober/November und April/Mai, d.h. nach Beginn und nach Ende der Heizperiode.
		</p>
		<strong>Kann ein Klavier, das 100 Jahre alt ist, überhaupt noch anständig spielen?</strong>
		<p>
			Antwort: Es kann. Die hervorragende Fertigungsqualität und die gute Rohstoffauswahl beim Klavierbau in der "guten alten Zeit" hat viele gute und dauerhafte Instrumente hervorgebracht, von denen sich die "modernen" Klaviere eine Scheibe
			abschneiden können.
		</p>
		<strong>Stimmstock kaputt? Platte gerissen?</strong>
		<p>
			Antwort: Kommt äußerst selten vor, wird aber häufig von Händlern diagnostiziert, um ein neues Klavier zu verkaufen.
		</p>
		<strong>Mechanik veraltet?</strong>
		<p>
			Antwort: Die Klaviermechanik ist grundsätzlich unverändert geblieben in den letzten hundert Jahren. Alle "Verschleißteile" ( Achsen, Filze, usw.) sind austauschbar. Lediglich die Dimension einer modernen Mechanik ist in der Regel kleiner,
			was sich jedoch eher negativ auf die Spielart auswirkt.
		</p>
		<strong> Wie klingen alte Instrumente?</strong>
		<p>
			Antwort: Diese Instrumente haben einen warmen, vollen, singenden Ton. Im Klangvolumen sind sie, allein schon durch ihre Größe, dem modernen Kleinklavier überlegen.
		</p>
		<strong>Wie gut hält die Stimmung?</strong>
		<p>
			Antwort: Grundsätzlich genau so gut wie bei einem Neuinstrument, vorrausgesetzt der Stimmstock ist in gutem Zustand.
		</p>
		<strong>Was mag ein Klavier nicht?</strong>
		<p>
			Antwort: Zugluft, direkte Sonneneinstrahlung, Fußbodenheizung, starke klimatische Schwankungen, Mangel an Pflege und Wartung.
		</p>
	</section>
</template>
